import React from "react"
import * as styles from "./booking.module.css"


function GlofoxBookings() {
    let glofox_classes_snippet = '<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.6.1/iframeResizer.min.js"></script> <iframe id="glofox_1" src="https://app.glofox.com/portal/#/branch/5f75b06a11604326e112dbac/classes-week-view?header=classes" width="100%" height="100%" scrolling="yes" frameborder="0"></iframe> <div style="display:flex; color:white; justify-content:center; text-decoration:none;font-family: Arial, Helvetica, sans-serif;"> powered by<a style="text-decoration: none; font-family: Arial, Helvetica, sans-serif; " href="https://www.glofox.com"><b> Glofox</b></a></div> <script> iFrameResize({ log: false, checkOrigin: false, tolerance: 10, sizeHeight: true, heightCalculationMethod: \'lowestElement\', minHeight: 300, scrolling: \'auto\' }, \'#glofox_1\') </script>'
    
    return (
        <section className={styles.wrapper} dangerouslySetInnerHTML={{__html: glofox_classes_snippet}} />
    )
}

export default GlofoxBookings