import React, { Component } from "react"
import Nav from "../components/nav/nav"
import Seo from "../components/seo/seo"
import GlofoxBookings from "../components/glofox/booking/booking"

class Bookings extends Component {
  render() {
    return (
      <Seo title={'Bookings'}>
          <GlofoxBookings />
          <Nav showLogo={true} isDarkBackground={true} asFab={true} />
      </Seo>
    )
  }
}

export default Bookings;
